import { MixDataLayerArgs } from "react-gtm-module";
import Analytics from "./ga-api";

/** GTM TagManager & GA 측정 프로토콜 차이점
 * 믹스 앱에서는 TagManager를 사용해 GA 이벤트를 전송합니다.
 * 예외 : 새 탭 오픈 시 열리는 크롬 익스텐션은 원격 호스팅 코드를 (js, wasm 등) 삽입할 수 없어, GA 측정 프로토콜을 사용해 이벤트를 전송합니다.
 * TagManager 라이브러리 : https://www.npmjs.com/package/react-gtm-module?activeTab=readme
 * GA 측정 프로토콜 : https://developers.google.com/analytics/devguides/collection/protocol/ga4/sending-events?client_type=gtag&hl=ko
 * 원격 호스팅 코드 안내 :  https://developer.chrome.com/docs/extensions/develop/migrate/remote-hosted-code?hl=ko
 */

const GTM_ID = "GTM-MKN84LQ";

/**
 * FIXME: require 사용에 따라 TagManager 타입이 any로 변경되어 있음. type annotation이 필요합니다.
 */

const TagManager =
  process.env.BUILD_TARGET === "extension" ? null : require("react-gtm-module");

const initTagManager = () => {
  if (!TagManager) return;

  if (process.env.NODE_ENV === "production") {
    TagManager.initialize({
      gtmId: GTM_ID,
      auth: "wRUsiN07hwUh3sUf4KKVeg",
      preview: "env-1",
    });
  } else {
    TagManager.initialize({
      gtmId: GTM_ID,
      auth: "vI8HQISiOJyudspnqCr7Fw",
      preview: "env-55",
    });
  }
};

const fireEvent = (dataLayer: MixDataLayerArgs["dataLayer"]) => {
  if (!dataLayer) return;

  if (!TagManager) {
    if ("event" in dataLayer) {
      Analytics.fireEvent(dataLayer.event, {
        page_title: document.title,
        page_location: document.location.href,
        ...dataLayer.params,
      });
    }
  } else {
    if ("event" in dataLayer) {
      TagManager.dataLayer({
        dataLayer: {
          event: dataLayer.event,
          ...dataLayer.params,
        },
      });
    } else {
      TagManager.dataLayer({
        dataLayer: {
          ...dataLayer.params,
        },
      });
    }
  }
};

export { fireEvent, initTagManager };
