import { ClassAttributes, HTMLAttributes, useContext } from "react";
import Image from "next/image";
import { Interpolation, Theme, css, useTheme } from "@emotion/react";
import { Text } from "components/core/Text";
import { Button } from "components/core/buttons/Button";
import { AppContext } from "contexts/AppContext";

// FIXME: 크롬 확장 프로그램 정식 런칭 시, open 변수 삭제하기
const open = false;

export default function ChromeBanner({
  ...props
}: ClassAttributes<HTMLDivElement> &
  HTMLAttributes<HTMLDivElement> & {
    css?: Interpolation<Theme>;
  }) {
  const theme = useTheme();
  const { browserName } = useContext(AppContext);
  const isChrome = browserName === "Chrome";

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 4px;
        padding: 44px 0 44px;
      `}
      {...props}
    >
      <Text
        css={css`
          font-size: 36px;
          font-style: normal;
          font-weight: 300;
          line-height: 130%; /* 46.8px */
        `}
        color="common.black.main"
        variant="h1"
        tag="h1"
      >
        마케터에게 필요한 모든 인사이트를 한 곳에
      </Text>
      <Text color="text.disabled" variant="body1" tag="p">
        {open && isChrome
          ? "이제 새 탭에서 최신 트렌드와 마케팅 인사이트를 발견하세요."
          : "믹스에서 최신 트렌드와 마케팅 인사이트를 발견하세요."}
      </Text>
      {open && isChrome && (
        <div
          css={css`
            display: flex;
            gap: 12px;
            margin-top: 32px;
          `}
        >
          <Image
            css={css`
              width: 48px;
              height: 48px;
              border-radius: 16px;
            `}
            src="/img/tile_13.png"
            alt="maily"
            width={24}
            height={24}
          />
          <Button
            css={css`
              padding: 12px 16px;
              border-radius: 16px;
            `}
            variant="filled"
            color="secondary"
            size="xl"
            onClick={() => {
              alert("확장 프로그램이 곧 오픈됩니다!");
            }}
          >
            <Image
              css={css`
                margin-right: ${theme.shape.radius.medium}px;
              `}
              src="/img/chrome.png"
              alt="chrome"
              width={24}
              height={24}
            />
            Chrome에 추가
          </Button>
        </div>
      )}
    </div>
  );
}
