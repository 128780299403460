import React, { useMemo } from "react";
import { GetServerSidePropsContext } from "next";
import type { AppProps } from "next/app";
import Head from "next/head";
import { useRouter } from "next/router";
import Script from "next/script";
import {
  ApolloCache,
  ApolloClient,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
  NormalizedCacheObject,
  from,
  fromPromise,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { GoogleOAuthProvider } from "@react-oauth/google";
import axios, { AxiosError } from "axios";
import { Layout } from "components/core/Layout";
import { MixThemeProvider } from "components/providers/MixThemeProvider";
import { SessionDocument } from "graphql/generated";
import "styles/globals.css";
import { UAParser } from "ua-parser-js";
import { getClient } from "utils/client";
import { DEVICE_TYPE } from "utils/enum";
import {
  buildAccessTokenCookie,
  buildSignOutCookie,
  fetchAccessToken,
  isHasuraJWTExpired,
} from "utils/jwt";

if (process.env.BUILD_TARGET === "extension") {
  axios.defaults.baseURL = "https://mix.day";
}

const createClient = (cache: ApolloCache<NormalizedCacheObject>) => {
  const errorLink = onError(
    ({ graphQLErrors, networkError, operation, forward }) => {
      if (graphQLErrors && isHasuraJWTExpired(graphQLErrors)) {
        return fromPromise(
          axios
            .get("/api/refresh")
            .then(() => true)
            .catch((error) => {
              if (error instanceof AxiosError && error.response) {
                if (error.response.status === 401) {
                  axios
                    .get("/api/signout")
                    .finally(() => window.location.reload());
                }
              }
            }),
        )
          .filter((value) => typeof value === "boolean")
          .flatMap(() => forward(operation));
      }

      if (networkError) console.log(`[Network error]: ${networkError}`);
    },
  );

  const httpLink = new HttpLink({
    uri: process.env.NEXT_PUBLIC_GRAPHQL_ENDPOINT,
    credentials: "include",
  });

  return new ApolloClient({
    link: from([errorLink, httpLink]),
    cache,
    credentials: "include",
  });
};

function MyApp({
  Component,
  pageProps,
  browserName,
  deviceType,
  ...props
}: AppProps<{
  __APOLLO_CACHE_DATA__?: any;
}> & {
  browserName: string;
  deviceType: DEVICE_TYPE;
}) {
  const client = useMemo(() => {
    const cache = new InMemoryCache();
    if (pageProps?.__APOLLO_CACHE_DATA__) {
      cache.restore(pageProps.__APOLLO_CACHE_DATA__);
    }
    return createClient(cache);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const router = useRouter();

  /* fb-pixel : page view */
  // useEffect(() => {
  //   window.fbq("track", "PageView");

  //   const handler = () => {
  //     window.fbq("track", "PageView");
  //   };

  //   router.events.on("routeChangeComplete", handler);

  //   return () => {
  //     router.events.off("routeChangeComplete", handler);
  //   };
  // }, [router.events]);

  return (
    <React.Fragment>
      <Head>
        <title>믹스(Mix) - 마케팅 인사이트 미디어</title>
        <meta
          name="description"
          content="마케팅 인사이트가 돋보이는 콘텐츠를 엄선하여 소개합니다."
        />
        <meta
          name="keywords"
          content="믹스, mix, 마케팅 인사이트, 마케팅 인사이트 미디어, 마케팅 믹스, 민트, 팀민트, 브랜드 마케팅, 콘텐츠 마케팅, 제휴 마케팅, 그로스해킹, CRM 마케팅, SEO, 퍼포먼스 마케팅, 마케팅 트렌드, 바이럴 마케팅, 디지털 마케팅, 온라인 마케팅, B2B 마케팅, 검색 엔진 마케팅, 팝업 스토어 마케팅, 숏폼 마케팅, 메타버스 마케팅, 마케터, 퍼포먼스 마케터, 그로스 마케터, 브랜드 마케터, 콘텐츠 마케터, 마케팅 전략, 마케팅 에이전시, 마케팅 대행사, 광고 대행사, 미디어, 마케팅 미디어, 마케팅 콘텐츠"
        />
        <meta
          name="google-site-verification"
          content="6xuMI3dr0H2XDiEaiyCo6VhJQTmJpCpuZYQ1kKsvHEU"
        />
        <meta
          name="naver-site-verification"
          content="f334b4ffc9a1a35575c6b33874a7b0cd42b1fbf8"
        />
        <meta key="og:image" property="og:image" content="/img/ogImage.png" />
        <meta
          name="facebook-domain-verification"
          content="p300csc9it98a5zvw4zpq447kfz6c7"
        />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Head>
      {process.env.BUILD_TARGET !== "extension" && (
        <>
          <Script
            id="crisp"
            strategy="afterInteractive"
            dangerouslySetInnerHTML={{
              __html: `window.$crisp=[];window.CRISP_WEBSITE_ID="ce54c92b-58fa-44bc-bde7-4b69cd6604da";(function(){d=document;s=d.createElement("script");s.src="https://client.crisp.chat/l.js";s.async=1;d.getElementsByTagName("head")[0].appendChild(s);})();`,
            }}
          />
          <Script
            id="fb-pixel"
            strategy="afterInteractive"
            dangerouslySetInnerHTML={{
              __html: `
        !function(f,b,e,v,n,t,s)
        {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
        n.callMethod.apply(n,arguments):n.queue.push(arguments)};
        if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
        n.queue=[];t=b.createElement(e);t.async=!0;
        t.src=v;s=b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t,s)}(window, document,'script',
        'https://connect.facebook.net/en_US/fbevents.js');
        fbq('init', '1682526328836280');
        `,
            }}
          />
        </>
      )}
      <MixThemeProvider>
        <GoogleOAuthProvider
          clientId={process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID}
        >
          <ApolloProvider client={client}>
            <Layout browserName={browserName} deviceType={deviceType}>
              <Component {...pageProps} />
            </Layout>
          </ApolloProvider>
        </GoogleOAuthProvider>
      </MixThemeProvider>
    </React.Fragment>
  );
}

MyApp.getInitialProps = async ({ ctx }: { ctx: GetServerSidePropsContext }) => {
  /* userAgent */
  const _userAgent = ctx.req?.headers["user-agent"];
  const parser = new UAParser(_userAgent);
  const result = parser.getResult();

  const browserName = result.browser.name ?? "";

  const deviceType =
    result.device.type === "mobile"
      ? DEVICE_TYPE.MOBILE
      : result.device.type === "tablet"
      ? DEVICE_TYPE.TABLET
      : DEVICE_TYPE.DESKTOP;

  /* 유저 accessToken refresh 여부 체크 */
  const { cookies } = ctx.req;
  const refreshToken = cookies?.refreshToken;
  const accessToken = cookies?.accessToken;

  if (refreshToken && !accessToken) {
    try {
      const accessToken = await fetchAccessToken(refreshToken);
      ctx.res.setHeader("set-cookie", buildAccessTokenCookie(accessToken));
    } catch (error) {
      ctx.res.setHeader("set-cookie", buildSignOutCookie());
    }
  }

  if (refreshToken && accessToken) {
    /* 유저 로그인 여부 체크 */
    const client = getClient({
      headers: {
        cookie: ctx.req.headers.cookie,
      },
      ctx,
    });

    const response = await client.query<SessionQuery, SessionQueryVariables>({
      query: SessionDocument,
    });

    const me = response.data.session.me;

    /* 회원가입 이후 정보 입력 완료 여부 체크 : career, position 중 하나라도 null일 때, #signup-form 으로 리다이렉트 */
    const signup_redirect = ctx.query.signup_redirect;

    if (me && (!me.position || !me.career) && signup_redirect !== "true") {
      ctx.res.writeHead(302, {
        Location: "/?signup_redirect=true#signup-form",
      });
      ctx.res.end();
    }
  }

  return {
    browserName,
    deviceType,
  };
};

export default MyApp;
