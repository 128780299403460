import { ComponentProps } from "react";
import { useRouter } from "next/router";
import { TextField } from "./TextField";
import { fireEvent } from "lib/ga";

export function SearchTextField({
  value,
  ...props
}: Omit<ComponentProps<typeof TextField>, "variant" | "startItem">) {
  const router = useRouter();

  return (
    <form
      onSubmit={(e) => {
        e.preventDefault();

        if (value !== "") {
          fireEvent({
            event: "content_search",
            params: {
              text: value as string,
            },
          });

          if (process.env.BUILD_TARGET === "extension") {
            router.push({
              protocol: "https",
              host: "mix.day",
              pathname: "/search",
              query: {
                text: value,
              },
            });
          } else {
            router.push({
              pathname: "/search",
              query: {
                text: value,
              },
            });
          }
        }
      }}
    >
      <TextField
        size="medium"
        variant="filled"
        placeholder="콘텐츠 검색"
        value={value}
        startItem={<TextField.StartIcon name="SearchFilled" />}
        {...props}
      />
    </form>
  );
}
