import { GetServerSidePropsContext, PreviewData } from "next";
import {
  ApolloClient,
  HttpLink,
  InMemoryCache,
  from,
  fromPromise,
} from "@apollo/client";
import { GraphQLErrors } from "@apollo/client/errors";
import { onError } from "@apollo/client/link/error";
import { parse } from "cookie";
import _, { isString } from "lodash";
import { ParsedUrlQuery } from "querystring";
import {
  buildAccessTokenCookie,
  buildSignOutCookie,
  fetchAccessToken,
} from "utils/jwt";

const isHasuraJWTExpired = (error: GraphQLErrors) => {
  return !!error.find((e) => e?.extensions?.code === "invalid-jwt");
};

export const getClient = ({
  uri = process.env.NEXT_PUBLIC_GRAPHQL_ENDPOINT,
  headers,
  ctx,
}: {
  uri?: string;
  headers?: any;
  ctx: GetServerSidePropsContext<ParsedUrlQuery, PreviewData> | null;
}) => {
  const isSSR = typeof window === "undefined";

  const errorLink = onError(
    ({ graphQLErrors, networkError, operation, forward }) => {
      if (graphQLErrors && isHasuraJWTExpired(graphQLErrors)) {
        if (ctx) {
          const { refreshToken } = parse(ctx.req.headers.cookie ?? "");

          return fromPromise(
            fetchAccessToken(refreshToken)
              .then((accessToken) => {
                const newCookie = buildAccessTokenCookie(accessToken);

                ctx.res.setHeader("set-cookie", newCookie);

                operation.setContext({
                  headers: {
                    ...operation.getContext().headers,
                    cookie: newCookie,
                  },
                });

                return accessToken;
              })
              .catch(() =>
                ctx.res.setHeader("set-cookie", buildSignOutCookie()),
              ),
          )
            .filter(isString)
            .flatMap(() => forward(operation));
        }

        return forward(operation);
      }

      if (networkError) console.log(`[Network error]: ${networkError}`);
    },
  );

  const httpLink = new HttpLink({
    uri,
    headers,
    credentials: "include",
  });

  return new ApolloClient({
    ssrMode: isSSR,
    link: from([errorLink, httpLink]),
    cache: new InMemoryCache({}),
    credentials: "include",
  });
};
