import { Interpolation, Theme, css } from "@emotion/react";
import { ClassAttributes, HTMLAttributes } from "react";
import { SideFooter } from "./Footer";
import { NavMenu } from "./NavMenu";

export const SideBar = ({
  ...props
}: ClassAttributes<HTMLElement> &
  HTMLAttributes<HTMLElement> & {
    css?: Interpolation<Theme>;
  }) => {
  return (
    <aside
      css={css`
        position: sticky;
        top: var(--header-height);
        left: 0;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        gap: 100px;
        height: calc(100vh - 120px);
        width: var(--sidebar-width);
        margin-right: var(--sidebar-margin);
      `}
      {...props}
    >
      <NavMenu showSubMenu />
      <SideFooter />
    </aside>
  );
};
