import { SVGProps } from "react";
import { Interpolation, Theme } from "@emotion/react";

export const Logo = (
  props: SVGProps<SVGSVGElement> & {
    css?: Interpolation<Theme>;
  },
) => {
  return (
    <svg
      width="42"
      height="16"
      viewBox="0 0 42 16"
      fill="#333333"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <path
        d="M20.0176 4.80273H21.3032V15.9482H20.0176V4.80273Z"
        fill="currentColor"
      />
      <path
        d="M12.1013 4.81158L14.045 4.81152L1.94375 15.9997L0 15.9997L12.1013 4.81158Z"
        fill="currentColor"
      />
      <path
        d="M19.0466 4.81158L20.9904 4.81152L8.88907 15.9997L6.94531 15.9997L19.0466 4.81158Z"
        fill="currentColor"
      />
      <path
        d="M12.8809 4.80273H14.2991L14.2993 7.71154L12.8809 9.11789V4.80273Z"
        fill="currentColor"
      />
      <path
        d="M25.086 4.80273H26.506L26.506 15.9492H25.0859L25.086 4.80273Z"
        fill="currentColor"
      />
      <rect
        x="24.5059"
        width="2.51968"
        height="2.51968"
        rx="1.25984"
        fill="currentColor"
      />
      <path
        d="M29.6172 4.80273H31.5158L41.1305 15.9637H39.2318L29.6172 4.80273Z"
        fill="currentColor"
      />
      <path
        d="M41.1309 4.80273H39.2322L29.6176 15.9637H31.5162L41.1309 4.80273Z"
        fill="currentColor"
      />
    </svg>
  );
};
