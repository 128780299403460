import { useId } from "react";
import { css, keyframes, useTheme } from "@emotion/react";

const rotate = keyframes`
  from {
    transform: rotateZ(0)
  }

  to {
    transform: rotateZ(360deg)
  }
`;

export const Spinner = ({
  size = 18,
  color,
  ...props
}: {
  size?: number;
  color?: string;
}) => {
  const id = useId();
  const theme = useTheme();
  const stroke = color || theme.palette.primary.main;

  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      stroke={stroke}
      css={[
        css`
          width: ${size}px;
          height: ${size}px;
          animation: ${rotate} 1s infinite;
        `,
      ]}
      {...props}
    >
      <path
        d="M16 8.99999C16 10.7009 15.3807 12.3436 14.2578 13.6212C13.1349 14.8988 11.5853 15.7238 9.89841 15.9421C8.21157 16.1604 6.50295 15.757 5.09182 14.8074C3.68069 13.8578 2.66361 12.4268 2.23062 10.7819C1.79763 9.13707 1.97835 7.39081 2.73903 5.86948C3.49971 4.34814 4.78828 3.15582 6.36398 2.5153C7.93968 1.87478 9.69469 1.82989 11.3011 2.38902C12.2408 2.71611 13.0921 3.23683 13.8044 3.90909"
        stroke={`url(#${id})`}
        strokeWidth="2.5"
        strokeLinecap="round"
      />
      <defs>
        <radialGradient
          id={id}
          cx="0"
          cy="0"
          r="1"
          gradientUnits="userSpaceOnUse"
          gradientTransform="translate(9 9) rotate(-23.1986) scale(9.6928)"
        >
          <stop stopColor={stroke} />
        </radialGradient>
      </defs>
    </svg>
  );
};
