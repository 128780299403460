import { ComponentProps, HTMLAttributes, forwardRef } from "react";
import { Menu } from "./Menu";
import { MENU_DEFAULT_PADDING } from "./MenuContext";
import { css } from "@emotion/react";
import { Text } from "components/core/Text";

const Component = forwardRef<
  HTMLDivElement,
  HTMLAttributes<HTMLDivElement> & {
    autoPadding?: boolean;
  }
>(({ children, autoPadding = false, ...props }, ref) => {
  return (
    <div
      ref={ref}
      css={css`
        display: flex;
        flex-direction: column;
        overflow: auto;

        ${autoPadding &&
        css`
          padding: ${MENU_DEFAULT_PADDING}px 0;
        `}
      `}
      {...props}
    >
      {children}
    </div>
  );
});

function MenuListHeader({
  children,
  ...props
}: {
  size?: ComponentProps<typeof Menu>["size"];
} & Omit<ComponentProps<typeof Text>, "variant" | "color">) {
  return (
    <Text
      css={css`
        padding: 2px 16px;
      `}
      variant="caption2"
      tag="header"
      color="text.secondary"
      {...props}
    >
      {children}
    </Text>
  );
}

const MenuList = Object.assign(Component, { Header: MenuListHeader });
Component.displayName = "MenuList";

export { MenuList };
