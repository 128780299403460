import { useCallback, useContext, useMemo } from "react";
import { useRouter } from "next/router";
import axios from "axios";
import { AuthContext } from "contexts/AuthContext";
import { getHash } from "utils/getHash";

export const useAuth = () => {
  const router = useRouter();

  const { session, loading, refetch } = useContext(AuthContext);

  const me = useMemo(() => session?.me ?? null, [session]);

  const isLoggedIn = useMemo(() => !!me, [me]);

  const hash = useMemo(() => getHash(router.asPath), [router]);

  const opened = useMemo(
    () => !isLoggedIn && hash === "signin",
    [isLoggedIn, hash],
  );

  const signOut = useCallback(async () => {
    await axios.get("/api/signout");
    window.location.href = "/";
  }, []);

  const openSignInModal = useCallback(() => {
    router.push({
      ...router,
      pathname: router.pathname,
      hash: "signin",
    });
  }, [router]);

  const closeSignInModal = useCallback(() => {
    router.push({
      ...router,
      pathname: router.pathname,
    });
  }, [router]);

  return {
    session,
    me,
    loading,
    isLoggedIn,
    opened,
    refetch,
    signOut,
    openSignInModal,
    closeSignInModal,
  };
};
