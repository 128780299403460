import { DetailedHTMLProps, HTMLAttributes } from "react";
import { css, useTheme } from "@emotion/react";

export function Divider(
  props: DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
) {
  const theme = useTheme();

  return (
    <div
      css={css`
        width: 100%;
        border-bottom: 1px solid ${theme.palette.components.divider.line};
      `}
      {...props}
    />
  );
}
