export class AppPage {
  public href: string;
  public label: string;
  public external: boolean;
  public iconName?: IconName;
  public children?: Array<AppPage>;

  constructor({
    href,
    label,
    external,
    iconName,
    children = [],
  }: {
    href: string;
    label: string;
    external: boolean;
    iconName?: IconName;
    children?: Array<AppPage>;
  }) {
    this.href = href;
    this.label = label;
    this.external = external;
    this.iconName = iconName;
    this.children = children;
  }

  active(pathname: string): boolean {
    return this.href === pathname;
  }
}
