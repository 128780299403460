import { useRef } from "react";
import Image from "next/image";
import { useRouter } from "next/router";
import { ExtensionLink } from "../ExtensionLink";
import { IconBox } from "../IconBox";
import { Text } from "../Text";
import { Button } from "../buttons/Button";
import { SideFooter } from "./Footer";
import { NavMenu } from "./NavMenu";
import { Theme, css, useTheme } from "@emotion/react";
import styled, { Interpolation } from "@emotion/styled";
import { HTMLMotionProps, motion } from "framer-motion";
import { useAuth } from "hooks/useAuth";
import { useOnClickOutside } from "usehooks-ts";

const UserBox = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  gap: 14px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
`;

const UserProfile = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
`;

export const Drawer = ({
  onClose,
  ...props
}: HTMLMotionProps<"aside"> & {
  onClose: () => void;
  css?: Interpolation<Theme>;
}) => {
  const theme = useTheme();
  const router = useRouter();
  const drawerRef = useRef(null);

  const { loading, me } = useAuth();

  useOnClickOutside(drawerRef, onClose);

  return (
    <motion.aside
      ref={drawerRef}
      css={css`
        display: flex;
        flex-direction: column;
        max-width: 100%;
        width: 300px;
        height: 100%;
        background-color: ${theme.palette.common.white.main};
      `}
      {...props}
    >
      <div
        css={css`
          display: flex;
          flex-direction: column;
          height: 194px;
          background-color: ${theme.palette.grey["a100"]};
        `}
      >
        <IconBox
          css={css`
            margin-top: 12px;
            margin-left: 12px;
            cursor: pointer;
          `}
          name="Close"
          size="xl"
          color={theme.palette.secondary.main}
          onClick={onClose}
        />
        <UserBox>
          {loading ? null : me ? (
            <ExtensionLink href="/mypage">
              <UserProfile>
                <Image
                  alt="profile"
                  src={me.profileImg}
                  width={36}
                  height={36}
                  css={css`
                    border-radius: 12px;
                    margin-bottom: 14px;
                  `}
                />
                <Text variant="subtitle2">{me.email}</Text>
                <Button
                  size="medium"
                  variant="text"
                  color="grey"
                  endIcon="ChevronRight"
                >
                  나의 정보 보기
                </Button>
              </UserProfile>
            </ExtensionLink>
          ) : (
            <div
              css={css`
                display: flex;
                flex-direction: column;
                align-items: center;
              `}
            >
              <ExtensionLink href="#signin">
                <Button
                  css={css`
                    margin-bottom: 12px;
                  `}
                  color="secondary"
                  size="large"
                >
                  믹스 로그인하기
                </Button>
              </ExtensionLink>
              <Text
                css={css`
                  color: #555555;
                  white-space: pre-wrap;
                  text-align: center;
                `}
                variant="body3"
                tag="div"
              >
                {`로그인을 하시면 더 많은 정보와\n기능을 이용할 수 있습니다`}
              </Text>
            </div>
          )}
        </UserBox>
      </div>
      <div
        css={css`
          display: flex;
          flex-direction: column;
          flex: 1;
          justify-content: space-between;
          padding: 20px;
        `}
      >
        <NavMenu showSubMenu={false} />
        <SideFooter />
      </div>
    </motion.aside>
  );
};
