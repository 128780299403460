import { css, useTheme } from "@emotion/react";

export const Switch = ({
  css: cssProp,
  disabled,
  checked,
  onChange,
  ...props
}: {
  css?: any;
  disabled?: boolean;
  checked: boolean;
  onChange: (value: boolean) => void;
}) => {
  const theme = useTheme();

  return (
    <div
      css={[
        css`
          width: 44px;
          height: 24px;
          padding: 4px;
          border-radius: 32px;
          background-color: ${checked
            ? "#3060be"
            : theme.palette.text.placeholder};
          transition: 300ms;
          cursor: pointer;

          ${disabled &&
          css`
            cursor: not-allowed;
            background-color: ${theme.palette.text.placeholder};
            opacity: 0.5;
          `}
        `,
        cssProp,
      ]}
      onClick={() => {
        if (!disabled) {
          onChange(!checked);
        }
      }}
      {...props}
    >
      <div
        css={css`
          width: 16px;
          height: 16px;
          background-color: ${theme.palette.common.white.main};
          border-radius: 100%;
          transition: 300ms;
          transform: ${checked
            ? "translateX(calc(44px - 24px))"
            : "translateX(0)"};
        `}
      />
    </div>
  );
};
