import { HTMLProps, forwardRef, useMemo } from "react";
import { css, useTheme } from "@emotion/react";

export type BaseFormBoxProps = {
  style?: React.CSSProperties;
  className?: string;
  size?: "large" | "medium";
  variant?: "outlined" | "filled";
  interaction?: "hover" | "focus";
  disabled?: boolean;
  error?: boolean;
};

/**
 * 'interaction' allows Component to trigger 'hover' or 'focus' CSS programatically.
 * For example, Select component isn't based on primitive <select> element, so it can't trigger :focus pseudo-class through css.
 * Instead, it can trigger :focus css through 'interaction' prop when it shows Menu dropdown.
 */
export const BaseFormBox = forwardRef<
  HTMLDivElement,
  Omit<HTMLProps<HTMLDivElement>, "size"> & BaseFormBoxProps
>(
  (
    {
      size = "medium",
      variant = "outlined",
      interaction,
      disabled,
      error,
      ...props
    },
    ref,
  ) => {
    const theme = useTheme();

    const { padding } = {
      large: {
        padding: "12px 20px",
      },
      medium: {
        padding: "4px 8px",
      },
    }[size];

    const status = useMemo(
      () => (error ? "error" : disabled ? "disabled" : "default"),
      [error, disabled],
    );

    const { borderRadius, borderColor, outline, backgroundColor } = {
      outlined: {
        borderRadius: theme.shape.radius.medium,
        borderColor: {
          default: theme.palette.grey[400],
          hover: theme.palette.primary.main,
          focus: theme.palette.primary.dark,
          disabled: theme.palette.action.disabled,
          error: theme.palette.error.main,
        },
        outline: {
          default: `none`,
          hover: `none`,
          focus: `1.3px solid ${theme.palette.primary.dark}`,
          disabled: `none`,
          error: `1.3px solid ${theme.palette.error.main}`,
        },
        backgroundColor: {
          default: theme.palette.common.white.main,
          hover: theme.palette.common.white.main,
          focus: theme.palette.common.white.main,
          disabled: theme.palette.common.white.main,
          error: theme.palette.common.white.main,
        },
      },
      filled: {
        borderRadius: theme.shape.radius.medium,
        borderColor: {
          default: "transparent",
          hover: theme.palette.grey[700],
          focus: theme.palette.grey[700],
          disabled: theme.palette.grey[400],
          error: theme.palette.error.main,
        },
        outline: {
          default: `none`,
          hover: `none`,
          focus: `1.3px solid ${theme.palette.grey[700]}`,
          disabled: `none`,
          error: `1.3px solid ${theme.palette.error.main}`,
        },
        backgroundColor: {
          default: theme.palette.grey[100],
          hover: theme.palette.grey[100],
          focus: theme.palette.grey[100],
          disabled: theme.palette.action.disabledBackground,
          error: theme.palette.error.selected,
        },
      },
    }[variant];

    return (
      <div
        ref={ref}
        css={css`
          padding: ${padding};
          box-shadow: inset 0 0 0 1px ${borderColor[status]};
          outline: ${outline[status]};
          background-color: ${backgroundColor[status]};
          border-radius: ${borderRadius}px;

          &[data-use-interaction="true"] {
            &:not(:focus):not(:focus-within):not(
                [data-interaction="focus"]
              ):hover,
            &[data-interaction="hover"] {
              box-shadow: inset 0 0 0 1px ${borderColor.hover};
              background-color: ${backgroundColor.hover};
              outline: ${outline.hover};
            }

            &:focus,
            &:focus-within,
            &[data-interaction="focus"] {
              box-shadow: inset 0 0 0 1px ${borderColor.hover};
              background-color: ${backgroundColor.focus};
              outline: ${outline.focus};
            }
          }

          &[data-disabled="true"] {
            box-shadow: inset 0 0 0 1px ${borderColor.disabled};
            background-color: ${backgroundColor.disabled};
            outline: ${outline.disabled};
            color: ${theme.palette.text.disabled};
            * {
              cursor: not-allowed;
            }
          }

          &[data-error="true"] {
            box-shadow: inset 0 0 0 1px ${borderColor.error};
            background-color: ${backgroundColor.error};
            outline: ${outline.error};
          }
        `}
        data-use-interaction={status === "default"}
        data-interaction={interaction}
        data-disabled={disabled}
        data-error={!!error}
        {...props}
      />
    );
  },
);

BaseFormBox.displayName = "BaseFormBox";
