import React, { useCallback, useEffect, useMemo } from "react";
import { useRouter } from "next/router";
import { MainBanner, NonDesktopMainBanner } from "./banners/MainBanner";
import { IntervalModal } from "./modals/IntervalModal";
import { Footer } from "./templates/Footer";
import { Global, css, useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { CareerModal } from "components/core/modals/CareerModal";
import { SignInModal } from "components/core/modals/SignInModal";
import { Header } from "components/core/templates/Header";
import { SideBar } from "components/core/templates/SideBar";
import { ChromeHomeBoard } from "components/domain/chrome-extension/ChromeHomeBoard";
import { AppContext } from "contexts/AppContext";
import { AuthContext } from "contexts/AuthContext";
import { useSessionQuery } from "graphql/generated";
import { usePathCheck } from "hooks/usePathCheck";
import { fireEvent, initTagManager } from "lib/ga";
import { DEVICE_TYPE } from "utils/enum";
import { getHash } from "utils/getHash";

const Container = styled.main<{ bgColor: string }>`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: ${({ bgColor }) => bgColor};
`;

const Wrapper = styled.div`
  flex: 1;
`;

const Contents = styled.div`
  width: calc(100% - var(--sidebar-width) - 48px);
  padding-bottom: 64px;

  @media (max-width: 1024px) {
    width: 100%;
  }

  @media (max-width: 768px) {
    padding-bottom: 0;
  }
`;

export const Layout = ({
  children,
  browserName,
  deviceType,
}: {
  children: React.ReactNode;
  browserName: string;
  deviceType: DEVICE_TYPE;
}) => {
  const theme = useTheme();
  const router = useRouter();

  const isHomePage = usePathCheck("/");
  const isOriginalContentPage = usePathCheck("/contents/original/[id]");
  const isPaymentsRoute = usePathCheck("/payments*");
  const isGoodbyePage = usePathCheck("/goodbye");

  const { data, loading, refetch } = useSessionQuery();
  const session = useMemo(() => data?.session ?? null, [data]);
  const hash = useMemo(() => getHash(router.asPath), [router]);

  const onCloseModal = useCallback(async () => {
    await router.push(
      {
        ...router,
        hash: null,
      },
      undefined,
      { scroll: false },
    );
  }, [router]);

  useEffect(() => {
    /* GA 정적 매개변수는 loading 이후 세팅 */
    if (loading) return;

    if (session?.me) {
      fireEvent({
        params: {
          user_id: session.me.id,
          career: session.me.career,
          position: session.me.position,
        },
      });

      /* crisp */
      (window as any)?.$crisp?.push(["set", "user:email", session.me.email]);
    }

    /* GA Init */
    initTagManager();
  }, [session, loading]);

  return (
    <AppContext.Provider
      value={{
        browserName,
        deviceType,
      }}
    >
      <AuthContext.Provider value={{ session, loading, refetch }}>
        <Global
          styles={css`
            :root {
              @media (max-aspect-ratio: 1/1) {
                --page-padding: 5vw;
              }

              --page-padding: max(
                48px,
                calc(100% * 0.2153846154 - 253.5384615px)
              );
              --page-max-width: 1800px;
              --header-height: 56px;
              --header-margin: 24px;
              --sidebar-width: 188px;
              --sidebar-margin: 48px;
              --logo-width: 89px;

              @media (max-width: 1024px) {
                --page-padding: 24px;
              }

              @media (max-width: 768px) {
                --page-padding: 16px;
                --header-height: 48px;
                --header-margin: 16px;
                --searchbar-height: 60px;
                --searchbar-padding-top: 8px;
                --searchbar-padding-bottom: 12px;
              }
            }

            ::-webkit-scrollbar {
              background-color: transparent;
              width: 10px;
              height: 10px;

              @media (max-width: 1024px) {
                display: none;
              }
            }

            ::-webkit-scrollbar-thumb {
              background-color: ${theme.palette.text.placeholder};
              border-radius: 8px;
            }

            ::-webkit-scrollbar-track {
              background-color: ${theme.palette.grey[200]};
            }
          `}
        />
        {isOriginalContentPage ? (
          <Container bgColor={theme.palette.common.white.main}>
            <Wrapper>{children}</Wrapper>
            <Footer
              css={css`
                @media (max-width: 1024px) {
                  display: none;
                }
              `}
            />
          </Container>
        ) : isPaymentsRoute || isGoodbyePage ? (
          <Container bgColor={theme.palette.common.white.main}>
            <Header
              showSearch={false}
              css={css`
                background-color: ${theme.palette.common.white.main};
                border-bottom: 1px solid ${theme.palette.text.focusVisible};
                margin-bottom: var(--header-margin);
              `}
            />
            <Wrapper
              css={css`
                padding: 0 var(--page-padding);
              `}
            >
              {children}
            </Wrapper>
            <Footer
              css={css`
                @media (max-width: 1024px) {
                  display: none;
                }
              `}
            />
          </Container>
        ) : (
          <Container
            css={css`
              max-width: var(--page-max-width);
              margin: 0 auto;
            `}
            bgColor={theme.palette.common.white.main}
          >
            {/* <TopBanner /> */}
            <Header
              showSearch={true}
              css={css`
                background-color: ${theme.palette.common.white.main};
                margin-bottom: var(--header-margin);
              `}
            />
            {isHomePage &&
              !router.query.tag &&
              (process.env.BUILD_TARGET === "extension" ? (
                <>
                  <ChromeHomeBoard />
                  <NonDesktopMainBanner />
                </>
              ) : (
                <MainBanner />
              ))}
            <Wrapper
              css={css`
                display: flex;
                width: 100%;
                padding: 0 var(--page-padding);
              `}
            >
              <SideBar
                css={css`
                  @media (max-width: 1024px) {
                    display: none;
                  }
                `}
              />
              <Contents>{children}</Contents>
            </Wrapper>
          </Container>
        )}
        {hash === "signin" && (
          <SignInModal
            isOpen={true}
            onRequestClose={onCloseModal}
            close={onCloseModal}
          />
        )}
        {hash === "signup-form" && session?.me && (
          <CareerModal
            me={session.me}
            isOpen={true}
            onRequestClose={onCloseModal}
            close={onCloseModal}
          />
        )}
        {/* <SurveyModal
          endDate="2023-09-09"
          isOpen={showSurveyModal}
          close={() => setShowSurveyModal(false)}
        /> */}
        <IntervalModal
          interval={1}
          startDate="2024-07-08"
          endDate="2024-07-31"
        />
      </AuthContext.Provider>
    </AppContext.Provider>
  );
};
