import { SerializedStyles, Theme, css, useTheme } from "@emotion/react";
import _ from "lodash";
type Path<T, K extends keyof T> = K extends string
  ? T[K] extends SerializedStyles
    ? K
    : T[K] extends Record<string, any>
    ? T[K] extends ArrayLike<any>
      ? K | `${K}.${Path<T[K], Exclude<keyof T[K], keyof any[]>>}`
      : K | `${K}.${Path<T[K], keyof T[K]>}`
    : K
  : never;
export function Text<
  T extends keyof JSX.IntrinsicElements,
  P extends keyof Theme["typography"],
  C extends keyof Theme["palette"]
>({
  variant,
  tag,
  color,
  ...props
}: Omit<JSX.IntrinsicElements[T], "variant" | "tag"> & {
  variant: Path<Theme["typography"], P>;
  color?: Path<Theme["palette"], C>;
  tag?: T;
}) {
  const theme = useTheme();
  const Tag = (tag || "span") as any;
  return (
    <Tag
      css={[
        _.get(theme.typography, variant),
        color
          ? css`
              color: ${_.get(theme.palette, color)};
            `
          : null,
      ]}
      {...props}
    />
  );
}
