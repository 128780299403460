import { useCallback, useEffect, useMemo, useState } from "react";

export enum Device {
  desktop = "desktop",
  tablet = "tablet",
  mobile = "mobile",
}

export enum ScreenOrientation {
  landscape = "landscape",
  portrait = "portrait",
}

export const useWindowSize = () => {
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [windowSize, setWindowSize] = useState({
    width: undefined as number | undefined,
    height: undefined as number | undefined,
  });

  const device = useMemo(
    () =>
      typeof windowSize.width === "number"
        ? windowSize.width <= 768
          ? Device.mobile
          : windowSize.width <= 1024
          ? Device.tablet
          : Device.desktop
        : Device.desktop,
    [windowSize],
  );

  const s = <T>(
    data: Array<[Device | "*", ScreenOrientation | "*", T]>,
    defaultValue: T,
  ): T => {
    const entry =
      data.find(([d, o]) => d === device && o === screenOrientation) ||
      data.find(([d, o]) => d === device && o === "*") ||
      data.find(([d, o]) => d === "*" && o === screenOrientation) ||
      data.find(([d, o]) => d === "*" && o === "*");

    if (entry) {
      return entry[2];
    }

    return defaultValue;
  };

  const selectDevice = useCallback(
    <T>(data: { [key in Device]: T }) => data[device],
    [device],
  );

  const isDesktop = useMemo(() => device === Device.desktop, [device]);

  const isMobile = useMemo(() => device === Device.mobile, [device]);

  const screenOrientation = useMemo<ScreenOrientation>(() => {
    const { width, height } = windowSize;

    if (typeof width !== "number" || typeof height !== "number") {
      return ScreenOrientation.landscape;
    }

    return width >= height
      ? ScreenOrientation.landscape
      : ScreenOrientation.portrait;
  }, [windowSize]);

  useEffect(() => {
    // only execute all the code below in client side
    if (typeof window !== "undefined") {
      // Handler to call on window resize
      const handleResize = () => {
        // Set window width/height to state
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      };

      // Add event listener
      window.addEventListener("resize", handleResize);

      // Call handler right away so state gets updated with initial window size
      handleResize();

      // Remove event listener on cleanup
      return () => window.removeEventListener("resize", handleResize);
    }
  }, []);

  return {
    ...windowSize,
    screenOrientation,
    device,
    isDesktop,
    isMobile,
    s,
    selectDevice,
  };
};
