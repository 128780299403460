import { useState } from "react";
import Image from "next/image";
import { css, useTheme } from "@emotion/react";
import { IconBox } from "components/core/IconBox";

export function ChromeSearchInput() {
  const theme = useTheme();
  const [search, setSearch] = useState("");

  return (
    <form
      css={css`
        display: flex;
        align-items: center;
        gap: 10px;
        width: 100%;
        height: 48px;
        padding: ${theme.shape.radius.medium}px 16px;
        border-radius: 75px;
        background: ${theme.palette.common.white.main};
        box-shadow: 0px 2px 12px 0px rgba(0, 0, 0, 0.12);
      `}
      onSubmit={(e) => {
        e.preventDefault();
        chrome.search.query({ text: search });
      }}
    >
      <IconBox size="large" name="SearchFilled" />
      <input
        css={css`
          flex: 1;
          border: none;
          outline: none;
          ${theme.typography.input.value};

          &::placeholder {
            color: ${theme.palette.text.disabled};
          }
        `}
        type="text"
        value={search}
        placeholder="Google 검색 또는 URL 입력"
        onChange={(e) => {
          setSearch(e.target.value);
        }}
      />
      <Image alt="google" src="/img/google.png" width={24} height={24} />
    </form>
  );
}
