import { HTMLProps, ReactNode, Suspense, forwardRef } from "react";
import { css } from "@emotion/react";
import * as icons from "lib/icons";

type IconProps = Omit<HTMLProps<HTMLElement>, "size"> & {
  name: IconName;
  fallback: ReactNode;
};

const iconCache = new Map();

export const Icon = forwardRef<HTMLElement, IconProps>(
  ({ name, fallback, ...props }, ref) => {
    iconCache.set(name, icons[name]);

    const Component = iconCache.get(name)!;

    return (
      <Suspense fallback={fallback}>
        <Component
          ref={ref}
          css={css`
            * {
              fill: currentColor;
            }
          `}
          {...props}
        />
      </Suspense>
    );
  },
);

Icon.displayName = "Icon";
