import { Interpolation, Theme, css } from "@emotion/react";
import { HTMLMotionProps, motion } from "framer-motion";

export const Overlay = ({
  ...props
}: HTMLMotionProps<"div"> & { css?: Interpolation<Theme> }) => {
  return (
    <motion.div
      css={css`
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100vw;
        height: 100vh;
        background: rgba(0, 0, 0, 0.4);
      `}
      {...props}
    />
  );
};
