import { ComponentProps, InputHTMLAttributes } from "react";
import { IconBox } from "../IconBox";
import { css } from "@emotion/react";

type IconStyle = {
  name: IconName;
  enabled: {
    color: string;
    backgroundColor?: string;
    borderRadius?: number;
  };
  hover: {
    color: string;
    backgroundColor?: string;
  };
};

type IconSpec = {
  checked: IconStyle;
  unchecked: IconStyle;
};

type ToggleIconProps = {
  checked: boolean;
  interaction?: "hover";
  iconSpec: IconSpec;
  size?: ComponentProps<typeof IconBox>["size"];
} & Omit<InputHTMLAttributes<HTMLInputElement>, "size">;

export function ToggleIcon({
  className,
  checked = false,
  interaction,
  iconSpec,
  size = "medium",
  ...props
}: ToggleIconProps) {
  const { name, enabled, hover } = checked
    ? iconSpec.checked
    : iconSpec.unchecked;

  return (
    <label
      css={css`
        display: inline-flex; // prevent height 100% or auto in flex container
        padding: 4px;
        background-color: ${enabled.backgroundColor};
        border-radius: ${enabled.borderRadius}px;

        ${hover &&
        css`
          &:hover,
          &[data-interaction="hover"] {
            background-color: ${hover.backgroundColor};
          }
        `}
      `}
      className={className}
    >
      <input
        css={css`
          display: none;
        `}
        readOnly
        type="checkbox"
        checked={checked}
        onClick={(e) => {
          e.stopPropagation();
        }}
        {...props}
      />
      <IconBox
        css={css`
          color: ${enabled.color};
          cursor: pointer;

          ${hover &&
          css`
            &:hover,
            &[data-interaction="hover"] {
              color: ${hover.color};
            }
          `}
        `}
        name={name}
        size={size}
        data-interaction={interaction}
      />
    </label>
  );
}
