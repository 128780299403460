import {
  ClassAttributes,
  ComponentProps,
  HTMLAttributes,
  MouseEventHandler,
  useEffect,
  useRef,
  useState,
} from "react";
import Image from "next/image";
import { useRouter } from "next/router";
import { Logo } from "../Logo";
import { Overlay, Overlay as SearchBarOverlay } from "../Overlay";
import { Button } from "../buttons/Button";
import { Drawer } from "./Drawer";
import { Interpolation, Theme, css, useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { ExtensionLink } from "components/core/ExtensionLink";
import { SearchTextField } from "components/core/inputs/SearchTextField";
import { SignInModal } from "components/core/modals/SignInModal";
import { AnimatePresence } from "framer-motion";
import { useAuth } from "hooks/useAuth";
import { useWindowSize } from "hooks/useWindowSize";
import { zIndex } from "styles/zIndex";
import { useOnClickOutside } from "usehooks-ts";

const GNB = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: var(--header-height);
  box-sizing: border-box;
`;

const LeftBox = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
`;

const MenuButton = (props: ComponentProps<typeof Button>) => {
  const theme = useTheme();

  return (
    <Button
      css={css`
        display: none;

        @media (max-width: 1024px) {
          display: flex;
          padding: 4px 0;

          svg {
            color: ${theme.palette.grey[800]} !important;
          }
        }
      `}
      variant="text"
      color="grey"
      size="medium"
      startIcon="Menu"
      {...props}
    />
  );
};

const LogoBox = styled.div`
  width: var(--sidebar-width);
  margin-right: var(--sidebar-margin);
  flex-shrink: 0;

  @media (max-width: 1024px) {
    width: auto;
    margin-right: 24px;
  }

  @media (max-width: 768px) {
    margin-right: 0;
  }
`;

const RightBox = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  justify-content: flex-end;
`;

const IconBox = styled.div`
  display: flex;
  align-items: center;
`;

const Profile = styled.div`
  position: relative;
  height: 36px;
  z-index: ${zIndex.profile};

  @media (max-width: 1024px) {
    display: none;
  }
`;

export const Header = ({
  children,
  showSearch,
  type = "default",
  onClose,
  ...props
}: ClassAttributes<HTMLDivElement> &
  HTMLAttributes<HTMLDivElement> & {
    css?: Interpolation<Theme>;
    showSearch: boolean;
    type?: "default" | "transparent" | "border";
    onClose?: MouseEventHandler<HTMLImageElement>;
  }) => {
  const theme = useTheme();
  const router = useRouter();
  const { me, loading, openSignInModal } = useAuth();
  const { pathname, query, push } = useRouter();

  const profileRef = useRef(null);
  const headerRef = useRef(null);
  const { isMobile } = useWindowSize();

  const [showSearchBar, setShowSearchBar] = useState(false);
  const [showDrawer, setShowDrawer] = useState(false);
  const [text, setText] = useState("");
  const [showModal, setShowModal] = useState(false);

  const { backgroundColor, borderColor, menuColor, logoColor } = {
    default: {
      backgroundColor: theme.palette.common.white.main,
      borderColor: theme.palette.common.white.main,
      menuColor: theme.palette.secondary.main,
      logoColor: theme.palette.common.black.main,
    },
    transparent: {
      backgroundColor: "transparent",
      borderColor: "transparent",
      menuColor: theme.palette.common.white.main,
      logoColor: theme.palette.common.white.main,
    },
    border: {
      backgroundColor: theme.palette.common.white.main,
      borderColor: theme.palette.text.focusVisible,
      menuColor: theme.palette.common.black.main,
      logoColor: theme.palette.common.black.main,
    },
  }[type];

  useOnClickOutside(headerRef, () => setShowSearchBar(false));

  useEffect(() => {
    if (isMobile && pathname !== "search") {
      setShowSearchBar(false);
    }
  }, [isMobile, pathname]);

  /* 검색 페이지에서 벗어날 때 텍스트를 초기화합니다 */
  useEffect(() => {
    if (pathname !== "/search") {
      setText("");
    } else {
      const queryText = query.text;

      if (typeof queryText === "string") {
        setText(queryText);
      }
    }
  }, [pathname, query, setText]);

  /* route change가 시작될 때 Drawer를 닫습니다 */
  useEffect(() => {
    const handler = () => {
      setShowDrawer(false);
    };

    router.events.on("routeChangeStart", handler);

    return () => {
      router.events.off("routeChangeStart", handler);
    };
  }, [router.events, onClose]);

  return (
    <>
      <header
        css={css`
          position: sticky;
          top: 0;
          padding: 0 var(--page-padding);
          z-index: ${zIndex.header};
          background-color: ${backgroundColor};
          border-bottom: 1.5px solid ${borderColor};
        `}
        ref={headerRef}
        {...props}
      >
        <GNB>
          <LeftBox>
            <MenuButton
              css={css`
                svg {
                  color: ${menuColor} !important;
                }
              `}
              onClick={() => setShowDrawer(true)}
            />

            <LogoBox>
              <ExtensionLink href="/">
                <Logo color={logoColor} />
              </ExtensionLink>
            </LogoBox>
          </LeftBox>
          <RightBox>
            {/* Tablet+Desktop SearchTextField - 검색 인풋 렌더링 */}
            {showSearch && (
              <SearchTextField
                css={css`
                  height: 32px;
                  max-width: 200px;
                  margin-right: 8px;

                  @media (max-width: 768px) {
                    display: none;
                  }
                `}
                value={text}
                onChange={(e) => setText(e.target.value)}
              />
            )}
            <IconBox>
              {/* Mobile SearchTextField - 아이콘 클릭 시 하단에 검색 인풋 렌더링 */}
              {showSearch && (
                <button
                  css={css`
                    display: none;

                    @media (max-width: 768px) {
                      display: block;
                      margin-right: 16px;
                    }
                  `}
                  type="button"
                  onClick={() => setShowSearchBar(true)}
                >
                  <svg
                    viewBox="0 0 21 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                    css={css`
                      display: none;
                      width: 20px;
                      height: 20px;
                      cursor: pointer;

                      @media (max-width: 768px) {
                        display: block;
                      }
                    `}
                  >
                    <path
                      d="M16.7154 8.46859C16.7154 12.7099 13.1633 16.1872 8.73268 16.1872C4.30209 16.1872 0.75 12.7099 0.75 8.46859C0.75 4.22727 4.30209 0.75 8.73268 0.75C13.1633 0.75 16.7154 4.22727 16.7154 8.46859Z"
                      stroke="#121212"
                      strokeWidth="1.5"
                    />
                    <path
                      d="M14.3438 14.5156L19.9971 19.998"
                      stroke="#121212"
                      strokeWidth="1.5"
                    />
                  </svg>
                </button>
              )}
              {children}
              {loading ? null : me ? (
                <ExtensionLink href="/mypage">
                  <Profile ref={profileRef}>
                    <Image
                      alt="profile"
                      src={me.profileImg}
                      width={36}
                      height={36}
                      style={{ cursor: "pointer", borderRadius: 8 }}
                    />
                  </Profile>
                </ExtensionLink>
              ) : process.env.BUILD_TARGET === "extension" ? (
                <ExtensionLink href="#signin">
                  <Button color="secondary" size="medium">
                    로그인
                  </Button>
                </ExtensionLink>
              ) : (
                <Button
                  color="secondary"
                  size="medium"
                  onClick={openSignInModal}
                >
                  로그인
                </Button>
              )}
            </IconBox>
          </RightBox>
        </GNB>
        {(pathname === "/search" || showSearchBar) && (
          <div
            css={css`
              display: none;

              @media (max-width: 768px) {
                position: absolute;
                top: var(--header-height);
                left: 0;
                display: block;
                width: 100%;
                height: var(--searchbar-height);
                padding-top: var(--searchbar-padding-top);
                padding-bottom: var(--searchbar-padding-bottom);
                padding-left: var(--page-padding);
                padding-right: var(--page-padding);
                background-color: ${theme.palette.common.white.main};
                z-index: ${zIndex.subHeader};
              }
            `}
          >
            <SearchTextField
              autoFocus
              value={text}
              onChange={(e) => setText(e.target.value)}
            />
          </div>
        )}
      </header>
      <AnimatePresence>
        {showDrawer && (
          <Overlay
            css={css`
              display: none;
              z-index: ${zIndex.drawer};

              @media (max-width: 1024px) {
                display: block;
              }
            `}
            initial="hidden"
            animate={showDrawer ? "visible" : "hidden"}
            exit="hidden"
            variants={{
              visible: {
                opacity: 1,
              },
              hidden: {
                opacity: 0,
              },
            }}
            transition={{
              ease: "easeOut",
              duration: 0.2,
            }}
          >
            <Drawer
              variants={{
                visible: {
                  x: 0,
                },
                hidden: {
                  x: "-100%",
                },
              }}
              onClose={() => setShowDrawer(false)}
            />
          </Overlay>
        )}
      </AnimatePresence>
      {pathname !== "/search" && showSearchBar && (
        <SearchBarOverlay
          css={css`
            display: none;

            @media (max-width: 768px) {
              display: block;
              z-index: ${zIndex.overlay_searchbar};
            }
          `}
        />
      )}
      {showModal && (
        <SignInModal
          isOpen={showModal}
          close={async () => setShowModal(false)}
        />
      )}
    </>
  );
};
