import React, { ComponentProps, forwardRef } from "react";
import { IconBox } from "../IconBox";
import { Spinner } from "../Spinner";
import { Button } from "./Button";
import { css } from "@emotion/react";

type IconButtonProps = {
  name: IconName;
  iconRef?: React.Ref<HTMLElement>;
} & Omit<ComponentProps<typeof Button>, "startIcon" | "endIcon">;

export const IconButton = forwardRef<HTMLButtonElement, IconButtonProps>(
  (
    {
      size = "medium",
      name,
      iconRef,
      disabled = false,
      loading = false,
      ...props
    },
    ref: React.Ref<HTMLButtonElement>,
  ) => {
    const padding = {
      xl: 12,
      large: 8,
      medium: 4,
      small: 2,
    }[size];

    /**
     * iconBoxWrapSize
     *
     * IconButton과 Button의 높이를 일치시키기 위해 사용됩니다.
     * 텍스트만 있는 Button과 IconButton이 동일한 높이를 가지도록 하기 위해
     * typography.button의 line-height를 최소 높이로 가진 div로 IconButton을 감싸서 높이를 조절합니다.
     */
    const iconBoxWrapSize = {
      xl: 32,
      large: 28,
      medium: 24,
      small: 24,
    }[size];

    return (
      <Button
        css={css`
          padding: ${padding}px;
        `}
        ref={ref}
        size={size}
        disabled={disabled || loading}
        {...props}
      >
        <div
          css={css`
            display: flex;
            align-items: center;
            justify-content: center;
            width: ${iconBoxWrapSize}px;
            height: ${iconBoxWrapSize}px;
          `}
        >
          {loading ? (
            <Spinner size={iconBoxWrapSize * 0.7} color="currentColor" />
          ) : (
            <IconBox ref={iconRef} name={name} size={size} />
          )}
        </div>
      </Button>
    );
  },
);

IconButton.displayName = "IconButton";
