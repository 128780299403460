import { css } from "@emotion/react";
import { AnimatePresence, HTMLMotionProps, motion } from "framer-motion";
import { zIndex } from "styles/zIndex";

export const MixModal = ({
  children,
  style,
  isOpen,
  onRequestClose,
  ...props
}: HTMLMotionProps<"div"> & {
  isOpen: boolean;
  onRequestClose?: () => void;
}) => {
  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          style={style}
          css={css`
            display: flex;
            align-items: center;
            justify-content: center;
            position: fixed;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: rgba(0, 0, 0, 0.5);
            z-index: ${zIndex.modal};
            width: 100%;
          `}
          {...props}
        >
          <div
            css={css`
              position: absolute;
              top: 0;
              bottom: 0;
              left: 0;
              right: 0;
              z-index: -1;
            `}
            onClick={onRequestClose}
          />
          {children}
        </motion.div>
      )}
    </AnimatePresence>
  );
};
