import { ButtonHTMLAttributes } from "react";
import { IconBox } from "../IconBox";
import { Text } from "../Text";
import { css, useTheme } from "@emotion/react";

type ToggleButton = {
  name?: IconName;
  active?: boolean;
  interaction?: "hover";
} & ButtonHTMLAttributes<HTMLButtonElement>;

export function ToggleButton({
  children,
  active = false,
  interaction,
  name,
  ...props
}: ToggleButton) {
  const theme = useTheme();

  const { backgroundColor, iconColor, textColor, hover } = active
    ? {
        backgroundColor: theme.palette.secondary.hover,
        iconColor: theme.palette.secondary.main,
        textColor: theme.palette.text.primary,
        hover: {},
      }
    : {
        backgroundColor: "transparent",
        iconColor: theme.palette.grey["400"],
        textColor: theme.palette.text.disabled,
        hover: {
          iconColor: theme.palette.secondary.main,
          textColor: theme.palette.text.primary,
        },
      };

  return (
    <button
      css={css`
        display: flex;
        align-items: center;
        gap: ${theme.shape.radius.medium}px;
        padding: ${theme.shape.radius.medium}px 16px;
        border-radius: ${theme.shape.radius.medium}px;
        background-color: ${backgroundColor};

        .icon {
          color: ${iconColor};
        }

        .text {
          color: ${textColor};
        }

        &:hover,
        &[data-interaction="hover"] {
          .icon {
            color: ${hover.iconColor};
          }

          .text {
            color: ${hover.textColor};
          }
        }
      `}
      type="button"
      data-interaction={interaction}
      {...props}
    >
      {name && <IconBox className="icon" size="medium" name={name} />}
      {children && (
        <Text className="text" variant="body2">
          {children}
        </Text>
      )}
    </button>
  );
}
