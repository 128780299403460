import {
  ButtonHTMLAttributes,
  ComponentProps,
  ReactNode,
  forwardRef,
  useRef,
} from "react";
import { IconBox } from "./IconBox";
import { Menu } from "./Menu";
import {
  MENU_ITEM_INDENT,
  MENU_ITEM_PADDING,
  useMenuContext,
} from "./MenuContext";
import { css, useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { IconButton } from "components/core/buttons/IconButton";
import { Checkbox } from "components/core/inputs/CheckBox";

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  width: 100%;
`;

const MainText = styled.div`
  ${({ theme }) => css`
    flex: 1;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    color: ${theme.palette.text.primary};
    ${theme.typography.body3};
  `}
`;

const SubText = styled.div`
  ${({ theme }) => css`
    min-width: fit-content;
    color: ${theme.palette.text.secondary};
    ${theme.typography.caption2};
  `}
`;

type MenuItemProps = {
  children: ReactNode;
  color?: "primary" | "error";
  space?: number;
  size?: ComponentProps<typeof Menu>["size"];
  startItem?: ReactNode;
  endItem?: ReactNode;
  selected?: boolean;
  interaction?: "hover" | "select";
  value?: string | number;
  subText?: string;
} & Omit<ButtonHTMLAttributes<HTMLButtonElement>, "children">;

const Component = forwardRef<HTMLButtonElement, MenuItemProps>(
  (
    {
      children,
      color = "primary",
      space = 0,
      size: menuItemSize,
      startItem,
      endItem,
      interaction,
      selected,
      value,
      subText,
      ...props
    },
    ref,
  ) => {
    const theme = useTheme();
    const mainTextRef = useRef<HTMLDivElement>(null);

    const { size: menuSize } = useMenuContext();
    const size = menuItemSize || menuSize;
    const padding = MENU_ITEM_PADDING[size];

    return (
      <button
        css={css`
          width: 100%;
          padding-top: ${padding.vertical}px;
          padding-bottom: ${padding.vertical}px;
          padding-left: ${padding.horizontal * 2 + MENU_ITEM_INDENT * space}px;
          padding-right: ${padding.horizontal * 2}px;
          text-align: left;
          cursor: pointer;

          ${color === "error" &&
          css`
            * {
              color: ${theme.palette.error.main} !important;
            }
          `}

          &:not(:disabled) {
            &:hover,
            &[data-interaction="hover"] {
              background-color: ${theme.palette.primary.hover};
            }

            &[data-interaction="select"],
            &[data-selected="true"] {
              background-color: ${theme.palette.primary.selected};
            }
          }

          &:disabled {
            background-color: transparent;
            color: ${theme.palette.grey["800"]};
            opacity: 0.5;
            cursor: not-allowed;
          }
        `}
        type="button"
        value={value}
        data-interaction={interaction}
        data-selected={selected}
        {...props}
      >
        <Wrapper>
          {startItem}
          <MainText ref={mainTextRef}>{children}</MainText>
          {subText && <SubText>{subText}</SubText>}
          {endItem}
        </Wrapper>
      </button>
    );
  },
);

function MenuItemIconBox(props: Omit<ComponentProps<typeof IconBox>, "size">) {
  return (
    <div
      css={css`
        display: flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;
      `}
    >
      <IconBox size="medium" {...props} />
    </div>
  );
}

function MenuItemIconButton(
  props: Omit<ComponentProps<typeof IconButton>, "size">,
) {
  return (
    <IconButton
      css={css`
        padding: 2px;
      `}
      size="small"
      {...props}
    />
  );
}

function MenuItemCheckbox(props: ComponentProps<typeof Checkbox>) {
  return (
    <div
      css={css`
        display: flex;
        align-items: center;
        justify-content: center;
        width: 24px;
        height: 24px;
      `}
    >
      <Checkbox size="medium" {...props} />
    </div>
  );
}

const MenuItem = Object.assign(Component, {
  IconBox: MenuItemIconBox,
  IconButton: MenuItemIconButton,
  Checkbox: MenuItemCheckbox,
});

Component.displayName = "Component";

export { MenuItem };
