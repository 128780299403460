import { DetailedHTMLProps, HTMLAttributes, useMemo } from "react";
import Image from "next/image";
import styled from "@emotion/styled";
import { motion } from "framer-motion";

const Container = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
`;

const TileGroup = styled(motion.div)`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const Tile = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 20px;
  background-color: white;
  user-select: none;
  overflow: hidden;

  @media (max-width: 1024px) {
    border-radius: 12px;
  }
`;

export const Tilerail = ({
  style,
  height,
  items,
  direction,
  duration,
  edge,
  gap,
  ...props
}: DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement> & {
  items: string[];
  height: number;
  edge: number;
  duration: number;
  direction: 1 | -1;
  gap: number;
}) => {
  const validItems = useMemo(() => items, [items]);
  const groupHeight = validItems.length * (edge + gap);

  return (
    <Container style={{ ...style, width: edge }} {...props}>
      <TileGroup
        initial={{ y: direction === 1 ? -groupHeight : 0 }}
        animate={{ y: direction === 1 ? 0 : -groupHeight }}
        transition={{
          duration,
          ease: "linear",
          repeat: Infinity,
        }}
      >
        {validItems.map((imgurl, index) => (
          <Tile
            key={index}
            style={{ width: edge, height: edge, marginBottom: gap }}
          >
            <Image
              alt="source"
              src={imgurl}
              fill={true}
              style={{ objectFit: "cover" }}
              sizes="(max-width: 768px) 56px, (max-width: 1024px) 64px, 80px"
            />
          </Tile>
        ))}
      </TileGroup>
      <TileGroup
        initial={{ y: direction === 1 ? -groupHeight : 0 }}
        animate={{ y: direction === 1 ? 0 : -groupHeight }}
        transition={{
          duration,
          ease: "linear",
          repeat: Infinity,
        }}
      >
        {validItems.map((imgurl, index) => (
          <Tile
            key={index}
            style={{ width: edge, height: edge, marginBottom: gap }}
          >
            <Image
              alt="source"
              src={imgurl}
              fill={true}
              style={{ objectFit: "cover" }}
              sizes="(max-width: 768px) 56px, (max-width: 1024px) 64px, 80px"
            />
          </Tile>
        ))}
      </TileGroup>
    </Container>
  );
};
