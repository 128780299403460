import { Config } from "./ChromeHomeBoard";
import { css, useTheme } from "@emotion/react";
import { Menu } from "components/core/Menu";
import { Switch } from "components/core/Switch";
import { Text } from "components/core/Text";
import { IconButton } from "components/core/buttons/IconButton";

export function ChromeHomeBoardConfigMenu({
  config,
  onChangeConfig,
  onSubmitTopSitesPermission,
}: {
  config: Config;
  onChangeConfig: (config: Config) => void;
  onSubmitTopSitesPermission: (granted: boolean) => void;
}) {
  const theme = useTheme();

  return (
    <Menu
      css={css`
        @media (max-width: 1024px) {
          display: none;
        }
      `}
      anchorEl={
        <IconButton
          css={css`
            padding: 6px;

            svg {
              color: ${theme.palette.grey[500]};
            }
          `}
          name="Setting"
          size="medium"
          variant="filled"
          color="grey"
        />
      }
      placement="left-start"
      offset={4}
    >
      <div
        css={css`
          display: grid;
          padding: 16px 20px;
          grid-template-columns: 110px 1fr;
          grid-auto-rows: 24px;
          grid-row-gap: 12px;

          label {
            display: flex;
            align-items: center;
          }
        `}
      >
        <Text variant="body3" tag="label">
          구글 검색창
        </Text>
        <Switch
          checked={config.use_chrome_search}
          onChange={() => {
            onChangeConfig({
              ...config,
              use_chrome_search: !config.use_chrome_search,
            });
          }}
        />
        <Text variant="body3" tag="label">
          바로가기 아이콘
        </Text>
        <Switch
          checked={config.has_topSites_permission && config.use_chrome_shortcut}
          onChange={async () => {
            if (config.has_topSites_permission) {
              onChangeConfig({
                ...config,
                use_chrome_shortcut: !config.use_chrome_shortcut,
              });
            } else {
              const granted = await chrome.permissions.request({
                permissions: ["topSites"],
              });

              onSubmitTopSitesPermission(granted);
            }
          }}
        />
      </div>
    </Menu>
  );
}
