import { ComponentProps } from "react";
import { ToggleIcon } from "./ToggleIcon";
import { useTheme } from "@emotion/react";

export function Checkbox(
  props: Omit<ComponentProps<typeof ToggleIcon>, "iconSpec">,
) {
  const theme = useTheme();

  return (
    <ToggleIcon
      iconSpec={{
        unchecked: {
          name: "CheckboxUnchecked",
          enabled: {
            color: theme.palette.grey[300],
          },
          hover: {
            color: theme.palette.grey[300],
          },
        },
        checked: {
          name: "CheckboxChecked",
          enabled: {
            color: theme.palette.primary.main,
          },
          hover: {
            color: theme.palette.primary.main,
          },
        },
      }}
      {...props}
    />
  );
}
