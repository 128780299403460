import React, {
  forwardRef,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import type {
  ElementRects,
  FloatingContext as FloatingContextType,
  Placement,
} from "@floating-ui/react";
import {
  FloatingPortal,
  arrow,
  autoUpdate,
  flip,
  offset as offsetMiddleware,
  size,
  useClick as useClickHook,
  useDismiss as useDismissHook,
  useFloating,
  useHover as useHoverHook,
  useInteractions,
  useMergeRefs,
} from "@floating-ui/react";
import { isNotNil } from "utils/isNotNil";

const ARROW_HEIGHT = 6;
const ARROW_TIP_RADIUS = 2;

type AppFloatingContextType = FloatingContextType &
  ReturnType<typeof useInteractions> & {
    arrowRef: React.RefObject<HTMLElement>;
    elementRects: ElementRects | undefined;
    fitToAnchor: boolean;
    closeOnClick: boolean;
    disabled: boolean;
    setUseArrow: React.Dispatch<React.SetStateAction<boolean>>;
  };

const FloatingContext = React.createContext<AppFloatingContextType>(
  undefined as any,
);

const Component = forwardRef(
  (
    {
      children,
      anchorEl,
      positionRef,
      defaultOpen,
      placement = "bottom-start",
      offset = 8,
      useClick = true,
      useDismiss = true,
      useHover = false,
      fitToAnchor = false,
      closeOnClick = false,
      disabled = false,
      hoverDelay,
      keyboardHandlers,
      onChange,
      ...props
    }: {
      /**
       * render is the floating element that will be positioned
       */
      anchorEl:
        | (React.ReactElement | undefined)
        | ((
            context: Pick<AppFloatingContextType, "open" | "onOpenChange"> & {
              close: () => void;
            },
          ) => React.ReactElement | undefined);
      /**
       * children is floating element that will be used to position the floating element
       */
      children?:
        | React.ReactNode
        | ((
            context: Pick<AppFloatingContextType, "open" | "onOpenChange"> & {
              close: () => void;
            },
          ) => React.ReactNode);
      positionRef?: React.RefObject<HTMLElement>;
      offset?: number;
      useClick?: boolean;
      useDismiss?: boolean;
      useHover?: boolean;
      defaultOpen?: boolean;
      placement?: Placement;
      fitToAnchor?: boolean;
      closeOnClick?: boolean;
      disabled?: boolean;
      hoverDelay?: Partial<{ open: number; close: number }>;
      open?: boolean;
      keyboardHandlers?: boolean;
      setOpen?: (value: boolean) => void;
      onChange?: (value: boolean) => void;
    },
    ref: any,
  ) => {
    const arrowRef = useRef<HTMLElement>(null);
    const [uncontrolledOpen, setUncontrolledOpen] = useState(defaultOpen);
    const [useArrow, setUseArrow] = useState(false);

    const [elementRects, setElementRects] = useState<ElementRects | undefined>(
      undefined,
    );

    const [open, onOpenChange] = useMemo(
      () =>
        disabled
          ? [false, () => false]
          : typeof props.open === "boolean"
          ? [props.open, props.setOpen]
          : [uncontrolledOpen, setUncontrolledOpen],
      [
        props.open,
        props.setOpen,
        uncontrolledOpen,
        setUncontrolledOpen,
        disabled,
      ],
    );

    const { context } = useFloating({
      open,
      onOpenChange: (value) => {
        onOpenChange?.(value);
        onChange?.(value);
      },
      whileElementsMounted: autoUpdate,
      placement,
      middleware: [
        offsetMiddleware(useArrow ? ARROW_HEIGHT + offset : offset),
        flip(),
        size({
          apply({ rects }) {
            setElementRects(rects);
          },
        }),
        arrow({
          element: arrowRef,
        }),
      ],
    });

    const click = useClickHook(context, {
      enabled: useClick,
      keyboardHandlers: keyboardHandlers ?? true,
    });

    const dismiss = useDismissHook(context, {
      enabled: useDismiss,
      outsidePressEvent: "click",
    });

    const hover = useHoverHook(context, {
      enabled: useHover,
      delay: hoverDelay || { open: 1000, close: 0 },
    });

    const interactions = useInteractions(
      [click, dismiss, hover].filter(isNotNil),
    );

    useEffect(() => {
      if (positionRef?.current) {
        context.refs.setPositionReference(positionRef.current);
      }
    }, [context.refs, positionRef]);

    return (
      <FloatingContext.Provider
        value={{
          ...context,
          ...interactions,
          arrowRef,
          elementRects,
          fitToAnchor,
          closeOnClick,
          disabled,
          setUseArrow,
        }}
      >
        <Anchor ref={ref} {...props}>
          {typeof anchorEl === "function"
            ? anchorEl({
                open: context.open,
                onOpenChange: context.onOpenChange,
                close: () => context.onOpenChange(false),
              })
            : anchorEl}
        </Anchor>
        <Float>
          {typeof children === "function"
            ? children({
                open: context.open,
                onOpenChange: context.onOpenChange,
                close: () => context.onOpenChange(false),
              })
            : children}
        </Float>
      </FloatingContext.Provider>
    );
  },
);

export const UseFloatingComponent = forwardRef<
  HTMLElement,
  { children: (context: AppFloatingContextType) => React.ReactElement }
>(({ children, ...props }, ref) => {
  const context = React.useContext(FloatingContext);

  if (!context) {
    throw new Error("UseFloating must be used within a Floating component");
  }

  return React.cloneElement(children(context), {
    ref,
    ...props,
  });
});

const Anchor = forwardRef(
  ({ children, ...props }: { children?: React.ReactElement }, ref: any) => {
    const { refs, disabled, getReferenceProps } = useContext(FloatingContext);
    const mergedRef = useMergeRefs([refs.setReference, ref]);

    if (!children) return null;

    return React.cloneElement(children, {
      ref: mergedRef,
      ...getReferenceProps({
        style: {
          cursor: !disabled ? "pointer" : undefined,
        },
        ...children.props,
        ...props,
      }),
    });
  },
);

const Float = ({ children }: { children: React.ReactNode }) => {
  const {
    refs,
    floatingStyles,
    open,
    elementRects,
    fitToAnchor,
    closeOnClick,
    onOpenChange,
    getFloatingProps,
  } = useContext(FloatingContext);

  if (!open) return null;

  return (
    <FloatingPortal>
      <div
        ref={refs.setFloating}
        style={{
          ...floatingStyles,
          minWidth: fitToAnchor ? elementRects?.reference.width : undefined,
          zIndex: 100000000,
        }}
        {...getFloatingProps({
          onClick: (e) => {
            /**
             * Prevent the event from bubbling up to the document
             */
            e.stopPropagation();

            if (closeOnClick) {
              onOpenChange(false);
            }
          },
        })}
      >
        {children}
      </div>
    </FloatingPortal>
  );
};

const Floating = Object.assign(Component, {});

export { Floating };

Component.displayName = "Component";
UseFloatingComponent.displayName = "UseFloatingComponent";
Anchor.displayName = "Anchor";
