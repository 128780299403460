import { ComponentProps, createContext, useContext } from "react";
import { Floating } from "components/core/Floating";

export type MenuContextProps = Pick<
  ComponentProps<typeof Floating>,
  "placement" | "fitToAnchor" | "defaultOpen" | "open" | "setOpen"
> & {
  size: "medium";
};

export const MenuContext = createContext<MenuContextProps | undefined>(
  undefined,
);

export const useMenuContext = () => {
  const context = useContext<MenuContextProps | undefined>(MenuContext);

  if (!context) {
    throw new Error("useMenuContext must be used within a MenuProvider");
  }

  return context;
};

export const MENU_DEFAULT_SIZE: MenuContextProps["size"] = "medium";
export const MENU_DEFAULT_PADDING = 8;
export const MENU_ITEM_INDENT = 24;
export const MENU_ITEM_PADDING: {
  [key in MenuContextProps["size"]]: Record<"vertical" | "horizontal", number>;
} = {
  medium: {
    vertical: 6,
    horizontal: 8,
  },
};
