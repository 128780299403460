import {
  Mix_Content_Tag_Enum_Enum,
  Order_Status_Enum,
} from "graphql/generated";

export const ORDERED_MIX_CONENT_TAG_ENUM_OBJECT: {
  [key in Mix_Content_Tag_Enum_Enum]: Mix_Content_Tag_Enum_Enum;
} = {
  [Mix_Content_Tag_Enum_Enum.MarketTrend]:
    Mix_Content_Tag_Enum_Enum.MarketTrend,
  [Mix_Content_Tag_Enum_Enum.NormalMarketing]:
    Mix_Content_Tag_Enum_Enum.NormalMarketing,
  [Mix_Content_Tag_Enum_Enum.BrandMarketing]:
    Mix_Content_Tag_Enum_Enum.BrandMarketing,
  [Mix_Content_Tag_Enum_Enum.ContentMarketing]:
    Mix_Content_Tag_Enum_Enum.ContentMarketing,
  [Mix_Content_Tag_Enum_Enum.AffiliateMarketing]:
    Mix_Content_Tag_Enum_Enum.AffiliateMarketing,
  [Mix_Content_Tag_Enum_Enum.GrowthHacking]:
    Mix_Content_Tag_Enum_Enum.GrowthHacking,
  [Mix_Content_Tag_Enum_Enum.CrmMarketing]:
    Mix_Content_Tag_Enum_Enum.CrmMarketing,
  [Mix_Content_Tag_Enum_Enum.PerformanceMarketing]:
    Mix_Content_Tag_Enum_Enum.PerformanceMarketing,
  [Mix_Content_Tag_Enum_Enum.Seo]: Mix_Content_Tag_Enum_Enum.Seo,
  [Mix_Content_Tag_Enum_Enum.Career]: Mix_Content_Tag_Enum_Enum.Career,
  [Mix_Content_Tag_Enum_Enum.Business]: Mix_Content_Tag_Enum_Enum.Business,
};

export const MIX_CONTENT_TAG_ENUM_KOREAN: {
  [key in Mix_Content_Tag_Enum_Enum]: string;
} = {
  [Mix_Content_Tag_Enum_Enum.MarketTrend]: "마켓트렌드",
  [Mix_Content_Tag_Enum_Enum.NormalMarketing]: "일반마케팅",
  [Mix_Content_Tag_Enum_Enum.BrandMarketing]: "브랜드마케팅",
  [Mix_Content_Tag_Enum_Enum.ContentMarketing]: "콘텐츠마케팅",
  [Mix_Content_Tag_Enum_Enum.AffiliateMarketing]: "제휴마케팅",
  [Mix_Content_Tag_Enum_Enum.GrowthHacking]: "그로스해킹",
  [Mix_Content_Tag_Enum_Enum.CrmMarketing]: "CRM마케팅",
  [Mix_Content_Tag_Enum_Enum.PerformanceMarketing]: "퍼포먼스마케팅",
  [Mix_Content_Tag_Enum_Enum.Seo]: "SEO",
  [Mix_Content_Tag_Enum_Enum.Career]: "커리어",
  [Mix_Content_Tag_Enum_Enum.Business]: "비즈니스",
};

export const ORDERED_MIX_CONTENT_TAG_ENUM = Object.values(
  ORDERED_MIX_CONENT_TAG_ENUM_OBJECT,
);

export enum Position {
  Brand = "브랜드 마케터",
  Performance = "퍼포먼스 마케터",
  Contents = "컨텐츠 마케터",
  CRM = "CRM 마케터",
  Growth = "그로스 해커",
  Etc = "기타",
}

export const ORDERED_POSITION_OBJECT: {
  [key in Position]: Position;
} = {
  [Position.Brand]: Position.Brand,
  [Position.Performance]: Position.Performance,
  [Position.Contents]: Position.Contents,
  [Position.CRM]: Position.CRM,
  [Position.Growth]: Position.Growth,
  [Position.Etc]: Position.Etc,
};

export const ORDERED_POSITION = Object.values(ORDERED_POSITION_OBJECT);

export const POSITION_KOREAN: {
  [key in Position]: string;
} = {
  [Position.Brand]: "브랜드 마케터",
  [Position.Performance]: "퍼포먼스 마케터",
  [Position.Contents]: "컨텐츠 마케터",
  [Position.CRM]: "CRM 마케터",
  [Position.Growth]: "그로스 해커",
  [Position.Etc]: "기타",
};

export const ORDERED_CAREERS = [
  "취준생",
  "신입",
  "1-2년",
  "3-5년",
  "6-10년",
  "10년 이상",
];

export const ORDER_STATUS_ENUM_KOREAN: {
  [key in Order_Status_Enum]: string;
} = {
  [Order_Status_Enum.Ready]: "미결제",
  [Order_Status_Enum.InProgress]: "승인 대기",
  [Order_Status_Enum.WaitingForDeposit]: "입금 대기",
  [Order_Status_Enum.Done]: "결제 완료",
  [Order_Status_Enum.Canceled]: "취소 완료",
  [Order_Status_Enum.WaitingForCanceled]: "취소 대기",
  [Order_Status_Enum.PartialCanceled]: "부분 취소",
  [Order_Status_Enum.Aborted]: "승인 실패",
  [Order_Status_Enum.Expired]: "시간 만료",
};

export enum DEVICE_TYPE {
  MOBILE = "mobile",
  TABLET = "tablet",
  DESKTOP = "desktop",
}
