import React, { ComponentProps } from "react";
import {
  MENU_DEFAULT_PADDING,
  MENU_DEFAULT_SIZE,
  MENU_ITEM_PADDING,
  MenuContext,
  MenuContextProps,
  useMenuContext,
} from "./MenuContext";
import { MenuItem } from "./MenuItem";
import { css, useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import { Divider } from "components/core/Divider";
import { Floating } from "components/core/Floating";

type MenuProps = ComponentProps<typeof Floating> &
  Partial<MenuContextProps> & {
    style?: React.CSSProperties;
    className?: string;
    autoPadding?: boolean;
    placeholder?: string;
    closeOnClick?: boolean;
  };

function Component({
  style,
  className,
  children,
  fitToAnchor = true,
  autoPadding = true,
  placeholder,
  size = MENU_DEFAULT_SIZE,
  offset = 4,
  ...props
}: MenuProps) {
  return (
    <Floating fitToAnchor={fitToAnchor} offset={offset} {...props}>
      {(context) => (
        <MenuContext.Provider value={{ size, setOpen: context.onOpenChange }}>
          <MenuContainer
            style={style}
            className={className}
            autoPadding={autoPadding}
          >
            {/*
             * Given design use MenuItem component as placeholder, not using CSS.
             * Instead of insert duplicated placeholder CSS, use MenuItem component as a placeholder.
             */}
            {(typeof children === "function" ? children(context) : children) ||
              (placeholder && (
                <MenuPlaceholder className="placeholder">
                  {placeholder}
                </MenuPlaceholder>
              ))}
          </MenuContainer>
        </MenuContext.Provider>
      )}
    </Floating>
  );
}

function MenuContainer({
  autoPadding = true,
  ...props
}: ComponentProps<"div"> & {
  autoPadding?: boolean;
}) {
  const theme = useTheme();

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        min-width: 160px;
        background-color: ${theme.palette.common.white.main};
        border: 1px solid ${theme.palette.line};
        border-radius: ${theme.shape.radius.medium}px;
        ${theme.effects.menuShadow};

        ${autoPadding &&
        css`
          padding: ${MENU_DEFAULT_PADDING}px 0;
        `}

        .placeholder {
          display: none;
        }

        &:has(.placeholder:first-of-type) {
          .placeholder {
            display: block !important;
          }
        }
      `}
      {...props}
    />
  );
}

function MenuDivider(props: ComponentProps<typeof Divider>) {
  return (
    <div
      css={css`
        padding: ${MENU_DEFAULT_PADDING}px 0;
      `}
      {...props}
    >
      <Divider />
    </div>
  );
}

function MenuPlaceholder({
  children,
  ...props
}: ComponentProps<typeof MenuItem>) {
  const theme = useTheme();
  const { size } = useMenuContext();

  return (
    <MenuItem
      css={css`
        pointer-events: none;
      `}
      {...props}
    >
      <div
        css={css`
          padding: ${MENU_ITEM_PADDING[size].vertical}px 0;
          color: ${theme.palette.text.secondary};
        `}
      >
        {children}
      </div>
    </MenuItem>
  );
}

const MenuPadding = styled.div`
  padding: ${MENU_DEFAULT_PADDING / 2}px 0;
`;

const MenuBottomButtonGroup = ({
  children,
  startItem,
  ...props
}: {
  children?: React.ReactNode;
  startItem?: React.ReactNode;
}) => {
  return (
    <div
      css={css`
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 8px 12px;
      `}
      {...props}
    >
      <div>{startItem}</div>
      <div
        css={css`
          display: flex;
          align-items: center;
          gap: 4px;
        `}
      >
        {children}
      </div>
    </div>
  );
};

const Menu = Object.assign(Component, {
  Container: MenuContainer,
  Divider: MenuDivider,
  Padding: MenuPadding,
  BottomButtonGroup: MenuBottomButtonGroup,
  Placeholder: MenuPlaceholder,
});

export { Menu };
