import { createContext } from "react";

export const AuthContext = createContext<{
  session: SessionQuery["session"] | null;
  loading: boolean;
  refetch: () => void;
}>({
  session: null,
  loading: false,
  refetch: () => {},
});
