import { useRouter } from "next/router";

export const usePathCheck = (path: string): boolean => {
  const { pathname } = useRouter();

  if (path.endsWith("*")) {
    const basePath = path.slice(0, -1);
    return pathname.startsWith(basePath);
  } else {
    return pathname === path;
  }
};
