/**
 * @desc throw when trying silent refresh without refresh token input
 */
export class RefreshTokenNotExistsError extends Error {
  constructor(message: string) {
    super(message);

    this.message = message;
    this.name = "RefreshTokenNotExistsError";
  }
}

/**
 * @desc throw when no one has the refresh token on DB
 */
export class RefreshTokenLostError extends Error {
  constructor(message: string) {
    super(message);

    this.message = message;
    this.name = "RefreshTokenLostError";
  }
}

/**
 * @desc throw when someone hold the refresh token, but expired
 */
export class RefreshTokenExpiredError extends Error {
  constructor(message: string) {
    super(message);

    this.message = message;
    this.name = "RefreshTokenExpiredError";
  }
}
