import { DEFAULT_SITE_LENGTH } from "./ChromeShortcut";
import { css, useTheme } from "@emotion/react";
import { Text } from "components/core/Text";
import { Button } from "components/core/buttons/Button";

export function ChromeTopSitesPermission({
  onSubmitTopSitesPermission,
}: {
  onSubmitTopSitesPermission: (granted: boolean) => void;
}) {
  const theme = useTheme();

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        align-items: center;
        width: var(--home-board-topSites-permission-width);
        gap: 28px;
      `}
    >
      <ul
        css={css`
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          align-items: center;
          width: 100%;
        `}
      >
        {Array.from({ length: DEFAULT_SITE_LENGTH + 1 }).map((_, index) => (
          <div
            css={css`
              width: 80px;
              aspect-ratio: 1 / 1;
              padding: 16px;
            `}
            key={index}
          >
            <svg width="100%" height="100%" xmlns="http://www.w3.org/2000/svg">
              <rect
                width="100%"
                height="100%"
                rx={12}
                ry={12}
                fill={theme.palette.grey[100]}
              />
            </svg>
          </div>
        ))}
      </ul>
      <div
        css={css`
          display: flex;
          align-items: center;
          gap: 12px;
          padding: 12px 16px;
          border-radius: 12px;
          border: 1px solid ${theme.palette.line};
          background: ${theme.palette.grey[50]};
        `}
      >
        <Text variant="caption2" color="text.primary">
          바로가기 아이콘을 계속 사용하시겠어요?
        </Text>
        <div
          css={css`
            display: flex;
            align-items: center;
            gap: ${theme.shape.radius.medium}px;
          `}
        >
          <Button
            variant="filled"
            color="grey"
            size="small"
            onClick={() => onSubmitTopSitesPermission(false)}
          >
            다음에 할게요
          </Button>
          <Button
            variant="filled"
            color="primary"
            size="small"
            onClick={async () => {
              const granted = await chrome.permissions.request({
                permissions: ["topSites"],
              });

              onSubmitTopSitesPermission(granted);
            }}
          >
            사용 할래요
          </Button>
        </div>
      </div>
    </div>
  );
}
