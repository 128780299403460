import { Dispatch, SetStateAction, useState } from "react";

type ChromeStorageArea = "local" | "sync" | "session";

export const useChromeStorage = <T>(
  key: string,
  initialValue: T,
  storageArea: ChromeStorageArea = "local",
): [T, Dispatch<SetStateAction<T>>] => {
  const [storedValue, setStoredValue] = useState<T>(() => {
    chrome.storage[storageArea].get([key], (result) => {
      if (result && result[key]) {
        setStoredValue(result[key]);
      } else {
        chrome.storage[storageArea].set({ [key]: initialValue });
      }
    });
    return initialValue;
  });

  const setValue: Dispatch<SetStateAction<T>> = (value) => {
    try {
      const newValue = value;
      chrome.storage[storageArea].set({ [key]: newValue });
      setStoredValue(newValue);
    } catch (error) {
      console.error(error);
    }
  };

  return [storedValue, setValue];
};
